/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAFASn6KKfYGquujofhwebARTSzdSJ6WP8",
  "appId": "1:847774287546:web:7c67aea2febbab9e926483",
  "authDomain": "schooldog-i-meriwether-ga.firebaseapp.com",
  "measurementId": "G-5QTV2LYK2K",
  "messagingSenderId": "847774287546",
  "project": "schooldog-i-meriwether-ga",
  "projectId": "schooldog-i-meriwether-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-meriwether-ga.appspot.com"
}
